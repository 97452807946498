<template>
  <div class="container">
    <h1> {{ label }} </h1>
  </div>
</template>

<script>
export default {
  name: 'ContactsPlaceHolder',
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.container{
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
h1{
  margin-top: 33%;
}
</style>

<template>
  <div class="community-member">
    <CommunityUser
      :name="member.name"
      :picture="member.picture"
      :user="member"
    />

    <div
      class="send-message-button base-button button"
      @click="sendMessage"
    >
      <img
        src="@assets/icon_message.svg"
        alt=""
        width="20"
      >
    </div>
  </div>
</template>

<script>
import CommunityUser from '@components/Communities/CommunityUser'
import messagingUtils from '@utils/messaging.utils'
export default {
  name: 'CommunityMember',
  components: { CommunityUser },
  props: {
    member: {
      type: Object,
      required: true
    },
    role: {
      type: String,
      default: ''
    }
  },
  methods: {
    sendMessage () {
      const messagingFormattedUser = {
        id: this.member.id,
        text: this.member.name,
        type: 1,
        userId: this.member.id
      }
      messagingUtils.newMessage([messagingFormattedUser])
    }
  }
}
</script>

<style lang="scss" scoped>

.community-member {
  display: flex;
  align-items: center;

  .send-message-button {
    margin-left: auto;
    border-radius: 50%;
    padding: 8px;

    img {
      margin-bottom: -2px;
    }
  }
}

</style>

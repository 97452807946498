<template>
  <div
    class="users-selection"
    :class="{'phone': mq.phone, 'tablet': mq.tablet}"
  >
    <div class="header">
      <h2>{{ $t('router.views.Communities.addMembersLabel') + ' - ' + modelValue.length }}</h2>
    </div>

    <div class="content">
      <CommunityMembersSearch @completion="updateMemberList" />

      <div class="member-list">
        <SelectionMember
          v-for="(cadycoMember, index) in sortedMemberList"
          :key="index"
          class="member"
          :is-selected="isSelected(cadycoMember)"
          :member="cadycoMember"
          :locked="isLocked(cadycoMember)"
          @select="selectUser(cadycoMember)"
          @unSelect="unSelectUser(cadycoMember)"
        />
      </div>
    </div>

    <div
      v-if="!mq.phone"
      class="selected-members"
    >
      <div
        v-t="'router.views.Communities.selectedMembers'"
        class="label"
      />
      <div class="selected-member-list">
        <SelectedUser
          v-for="(selectedUser, index) in modelValue"
          :key="index"
          class="selected-member"
          :locked="isLocked(selectedUser)"
          :user="selectedUser"
          @remove="unSelectUser(selectedUser)"
        />
      </div>
    </div>

    <Button
      v-else
      class="submit-button dark"
      :label="$t('AppCommonsLabels.submitButton')"
      @click="closeUserSelection"
    />
  </div>
</template>

<script>
import communities from '@/api/communities/communities.service'
import SelectionMember from '@components/Communities/SelectionMember'
import SelectedUser from '@components/Communities/SelectedUser'
import CommunityMembersSearch from '@components/Communities/CommunityMembersSearch'

export default {
  name: 'UsersSelection',
  components: { CommunityMembersSearch, SelectedUser, SelectionMember },
  inject: ['mq'],
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      validator: (val) => ['create', 'update'].includes(val),
      required: true
    },
    selectedCommunity: {
      type: Object,
      default: () => {
        return undefined
      }
    },
    selectCreator: {
      type: Boolean,
      default: true
    },
    lockCreator: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'closeUserSelection'],
  data () {
    return {
      memberList: []
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.auth.user
    },
    sortedMemberList () { // Put locked member in first position
      const sortedMembers = [...this.memberList]
      for (let i = 0; i < this.memberList.length; ++i) {
        if (this.isLocked(this.memberList[i])) {
          const creator = sortedMembers[i]
          sortedMembers.splice(i, 1)
          sortedMembers.unshift(creator)
          break
        }
      }
      return sortedMembers
    }
  },
  created () {
    this.getAllCadycoMembers()
    this.$emit('update:modelValue', [])
  },
  beforeUnmount () { // Essential in dev mode to avoid error when re-render component, I don't really understand why...
    this.$emit('update:modelValue', [])
  },
  methods: {
    getAllCadycoMembers () {
      communities.getAllCadycoUsers().then((data) => {
        if (data.success) {
          if (!this.selectCreator) {
            this.removeCreatorFromUserList(data.users)
          }
          this.memberList = data.users
          this.updateSelectedMembers()
        } else {
          console.error('Error while getting Cadyco users', data.error)
        }
      })
    },
    updateMemberList (newMemberList) {
      if (!this.selectCreator) {
        this.removeCreatorFromUserList(newMemberList)
      }
      this.memberList = newMemberList
    },
    updateSelectedMembers () {
      if (this.mode === 'create' && this.lockCreator) {
        // Push current user
        const copy = this.modelValue.slice()
        for (let i = 0; i < this.memberList.length; ++i) {
          if (this.memberList[i].id === this.currentUser.id) {
            copy.push(this.memberList[i])
          }
        }
        this.$emit('update:modelValue', copy)
      }
      if (this.mode === 'update') {
        // Push selected community members
        const copy = this.modelValue.slice()
        for (let i = 0; i < this.selectedCommunity.members.length; ++i) {
          copy.push(this.selectedCommunity.members[i])
        }
        this.$emit('update:modelValue', copy)
      }
    },
    isSelected (user) { // computed?
      for (let i = 0; i < this.modelValue.length; ++i) {
        if (user.id === this.modelValue[i].id) {
          return true
        }
      }
      return false
    },
    isLocked (user) {
      if (this.mode === 'create' && this.lockCreator) {
        return user.id === this.currentUser.id
      } else if (this.mode === 'update') {
        return user.id === this.selectedCommunity.creatorId
      }
    },
    selectUser (user) {
      const copy = this.modelValue.slice()
      copy.push(user)
      this.$emit('update:modelValue', copy)
    },
    unSelectUser (user) {
      const copy = this.modelValue.slice()
      for (let i = 0; i < this.modelValue.length; ++i) {
        if (user.id === this.modelValue[i].id) {
          copy.splice(i, 1)
          break
        }
      }
      this.$emit('update:modelValue', copy)
    },
    removeCreatorFromUserList (userList) {
      const index = userList.map(user => user.id).indexOf(this.currentUser.id)
      if (index !== -1) {
        userList.splice(index, 1)
      }
    },
    closeUserSelection () {
      this.$emit('closeUserSelection')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.users-selection {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;

  .header {
    padding-top: 5px;
    display: flex;
    align-items: center;
  }

  .content {
    min-height: 0;
    background-color: $dark-white-bg;
    flex: 1;
    padding: 15px;
    display: flex;
    flex-direction: column;
  }

  .member-list {
    flex: 1;
    min-height: 0;
    padding-top: 10px;
    /* for Firefox */
    overflow-y: auto;
    overflow-x: visible;
  }

  .selected-members {
    margin-top: 2rem;
    margin-bottom: 10px;
    border-radius: 10px;

    .label {
      margin: 5px
    }

    .selected-member-list {
      display: flex;
      flex-wrap: wrap;
      max-height: 130px;
      overflow-y: auto;

      .selected-member {
        margin: 5px;
      }
    }
  }

  &.phone {
    .content {
      padding: 10px;
    }

    .member-list {
      padding-top: 0;
    }

    .submit-button {
      margin: 10px 0;
      height: 48px;
    }
  }

  &.tablet {
    padding: 5px;

    .content {
      padding: 10px;
    }

    .member-list {
      padding-top: 0;
    }
  }
}
</style>

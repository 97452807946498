<template>
  <div
    class="community"
    :class="{'selected': selected}"
  >
    <div class="thumbnail">
      <img
        src="@assets/icon_cadyco-commu-black.svg"
        alt=""
        width="30"
      >
    </div>
    <div class="data">
      <div class="name">
        {{ community.name }}
      </div>
      <div
        v-if="info === 'createDate'"
        class="info create-date"
      >
        {{ formattedCreationDate }}
      </div>
      <div
        v-if="info === 'nbMembers'"
        class="info nb-members"
      >
        {{ formattedNbMembers }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CommunityPreview',
  props: {
    community: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    info: {
      type: String, // Enum on ['none', 'createDate', 'nbMembers']
      default: 'none'
    }
  },
  computed: {
    formattedCreationDate () {
      return moment(this.community.createDate, 'DD-MM-YYYY HH:mm:ss').calendar()
    },
    formattedNbMembers () {
      return this.community.memberCount + ' ' +
        (this.community.memberCount > 1 ? this.$t('router.views.Communities.members') : this.$t('router.views.Communities.member'))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.community {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;

  .thumbnail {
    height: 70px;
    width: 70px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .data {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .name {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 2px;
    }

    .create-date {
      margin-top: 2px;
      font-size: 0.875rem;
    }

    .nb-members {
      font-size: 14px;
      font-weight: bold;
    }
  }

  &:hover {
    background-color: $dark-white-bg;
  }

  &.selected {
    background-color: $dark-white-bg;
    color: $public-bg;
  }
}
</style>

<template>
  <div class="community-user">
    <img
      class="picture"
      :src="picture"
      alt="user avatar"
    >
    <div class="user-infos">
      <div class="name">
        {{ name }}
      </div>
      <div
        v-if="role !== ''"
        class="role"
      >
        {{ role }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommunityUser',
  props: {
    name: {
      type: String,
      required: true
    },
    picture: {
      type: String,
      required: true
    },
    role: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.community-user {
  display: flex;
  align-items: center;

  .picture {
    border-radius: 50px;
    width: 45px;
    height: 45px;
  }

  .user-infos {
    height: 100%;
    font-size: 14px;
    margin-left: 15px;
    display: flex;
    flex-direction: column;

    .role {
      font-weight: bold;
    }
  }
}
</style>

<template>
  <AuthenticatedLayout>
    <div
      class="communities theme-text-color"
      :class="{'phone': mq.phone, 'tablet': mq.tablet}"
    >
      <CommunityListPanel
        v-if="mode === 'display'"
        @changeMode="changeMode"
      />
      <CreateCommunityPanel
        v-else-if="mode === 'update' || mode === 'create'"
        :mode="mode"
        :members="createCommunityMembers"
        @closePanel="changeMode('display')"
        @openUserSelection="isSelectUser = true"
      />

      <!-- Right panel -->
      <CommunityDisplay
        v-if="selectedCommunity !== undefined && mode === 'display'"
        class="right-panel"
        @displayCommunityOptions="displayCommunityOptions"
      />
      <UsersSelection
        v-else-if="(selectedCommunity !== undefined && mode === 'update') || mode === 'create'"
        v-show="(!mq.phone) || isSelectUser"
        v-model="createCommunityMembers"
        :selected-community="selectedCommunity"
        :mode="mode"
        :lock-creator="true"
        class="right-panel user-selection"
        @closeUserSelection="isSelectUser = false"
      />
      <ContactsPlaceHolder
        v-else-if="!mq.phone && !mq.tablet"
        :label="$t('router.views.Communities.communityPlaceholder')"
        class="right-panel"
      />
    </div>

    <ContextMenu
      v-if="isAContextMenuDisplayed && isContextMenuDisplayed"
      @chooseOption="performChosenOption"
      @close="isContextMenuDisplayed=false"
    />

    <teleport to="body">
      <CreateMessageModal
        v-if="isCreateMessageModalDisplayed"
      />
    </teleport>
  </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@layouts/AuthenticatedLayout'
import communities from '@/api/communities/communities.service'
import ContextMenu from '@components/ContextMenu/ContextMenu'
import utils from '@utils/utils'
import contextMenus from '@utils/contextMenus'
import CreateCommunityPanel from '@components/Communities/CreateCommunityPanel'
import UsersSelection from '@components/Communities/UsersSelection'
import ContactsPlaceHolder from '@components/Contacts/ContactsPlaceHolder'
import CommunityDisplay from '@components/Communities/CommunityDisplay'
import CommunityListPanel from '@components/Communities/CommunityListPanel'
import CreateMessageModal from '@components/Messaging/CreateMessageModal'

export default {
  name: 'Communities',
  components: { CreateMessageModal, CommunityListPanel, CommunityDisplay, ContactsPlaceHolder, UsersSelection, CreateCommunityPanel, ContextMenu, AuthenticatedLayout },
  inject: ['mq'],
  data () {
    return {
      mode: 'display',
      createCommunityName: '',
      createCommunityDescription: '',
      createCommunityMembers: [],
      isSelectUser: false,
      isContextMenuDisplayed: false
    }
  },
  computed: {
    selectedCommunity () {
      return this.$store.state.communities.selectedCommunity
    },
    userCommunities () {
      return this.$store.state.communities.userCommunities
    },
    selectedCommunityMemberLabel () {
      return this.selectedCommunity.memberCount > 1 ? this.$t('router.views.Communities.members') : this.$t('router.views.Communities.member')
    },
    isCreateMessageModalDisplayed () {
      return this.$store.state.messaging.isCreateMessageModalDisplayed
    },
    isAContextMenuDisplayed () {
      return this.$store.state.contextMenu.isAContextMenuDisplayed
    },
    contextMenuOptions () {
      const options = utils.cloneArrayOfObject(contextMenus.communityOptions)
      if (!this.selectedCommunity.permissions.delete) {
        utils.removeMenuOptionIfExist(options, 'delete')
      }
      if (!this.selectedCommunity.permissions.modify) {
        utils.removeMenuOptionIfExist(options, 'modify')
      }
      if (!this.selectedCommunity.permissions.leave) {
        utils.removeMenuOptionIfExist(options, 'leave')
      }
      return options
    }
  },
  created () {
    this.$store.dispatch('communities/getUserCommunities', { idCommunityToSelect: this.$route.params.communityId, mq: this.mq, router: this.$router })
    this.$watch(
      () => this.$route.params,
      () => {
        if (this.$route.params.communityId) {
          const index = this.userCommunities.map(community => community.id).indexOf(this.$route.params.communityId)
          this.$store.dispatch('communities/selectCommunity', this.userCommunities[index])
        } else {
          this.$store.dispatch('communities/selectCommunity', undefined) // necessary?
        }
      }
    )
  },
  methods: {
    changeMode (newMode) {
      this.mode = newMode
    },
    displayCommunityOptions (event) {
      if (!this.isAContextMenuDisplayed && this.contextMenuOptions.length > 0) {
        this.isContextMenuDisplayed = true
        this.$store.dispatch('contextMenu/openContextMenu',
          {
            event: event,
            options: this.contextMenuOptions
          })
      }
    },
    performChosenOption (option) {
      switch (option) {
        case 'delete':
          this.$store.dispatch('warningModal/addWarning', {
            text: this.$t('router.views.Communities.Warning.deleteCommunity'),
            lastAction: { fct: this.deleteCommunity, params: [this.selectedCommunity] }
          })
          break
        case 'modify':
          this.changeMode('update')
          break
        case 'leave':
          this.$store.dispatch('warningModal/addWarning', {
            text: this.$t('router.views.Communities.Warning.leaveCommunity'),
            lastAction: { fct: this.leaveCommunity, params: [this.selectedCommunity] }
          })
          break
        default:
          console.error('unknown action for option', option)
      }
      this.isContextMenuDisplayed = false
      this.$store.dispatch('contextMenu/closeMenus')
    },
    deleteCommunity () {
      const communityToSelectAfter = this.$store.getters['communities/previousCommunity']
      const idCommunityToSelectAfter = communityToSelectAfter && !this.mq.phone && !this.mq.tablet ? communityToSelectAfter.id : undefined
      communities.deleteCommunity(this.selectedCommunity.id).then((data) => {
        if (data.success) {
          this.$store.dispatch('communities/getUserCommunities', { idCommunityToSelect: idCommunityToSelectAfter, mq: this.mq, router: this.$router })
        } else {
          console.error('Error while deleting community', data.error)
        }
      })
    },
    leaveCommunity () {
      const communityToSelectAfter = this.$store.getters['communities/previousCommunity']
      const idCommunityToSelectAfter = communityToSelectAfter && !this.mq.phone && !this.mq.tablet ? communityToSelectAfter.id : undefined
      communities.leaveCommunity(this.selectedCommunity.id).then((data) => {
        if (data.success) {
          this.$store.dispatch('communities/getUserCommunities', { idCommunityToSelect: idCommunityToSelectAfter, mq: this.mq, router: this.$router })
        } else {
          console.error('Error while deleting community', data.error)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.communities{
  display: flex;
  height: calc(100% - #{$desktop-search-bar-height});

  .right-panel {
    flex: 3;
    display: flex;
    flex-direction: column;
  }

  &.phone {
    position: relative;
    height: calc(100% - #{$mobile-search-bar-height});

    .right-panel {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
    }
  }

  &.tablet {
    position: relative;
    height: calc(100% - #{$desktop-search-bar-height});

    .right-panel:not(.user-selection) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
    }

    .user-selection {
      position: relative;
      width: auto;
      height: auto;
      flex: 2;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>

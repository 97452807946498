<template>
  <div
    class="user"
    data-test="selected-user"
  >
    <CommunityUser
      class="user-name"
      :name="user.name"
      :picture="user.picture"
      :user="user"
    />
    <BaseIcon
      v-if="!locked"
      class="delete"
      name="times"
      @click="removeUser"
    />
  </div>
</template>

<script>
import BaseIcon from '@components/Base/BaseIcon'
import CommunityUser from '@components/Communities/CommunityUser'

export default {
  name: 'SelectedUser',
  components: { BaseIcon, CommunityUser },
  props: {
    user: {
      type: Object,
      required: true
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  emits: ['remove'],
  methods: {
    removeUser () {
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.user {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: $dark-white-bg;
  padding: 15px;
}

.user-name{
  margin-left: 5px;
}

.delete{
  margin-left: 10px;
  cursor: pointer;
  font-size: 15px;
}

</style>

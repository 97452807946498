<template>
  <div class="search-bar">
    <img
      class="search-icon"
      src="@/assets/icon_search.svg"
      width="15"
    >
    <Input
      v-model="inputText"
      type="text"
      :placeholder="$t('router.views.Communities.searchPlaceholder')"
      class="search-input"
      @input="searchTimeOut"
    />
    <!-- <BaseIcon
      v-if="inputText !== ''"
      class="erase-text"
      name="times"
      @click="erase"
    /> -->
  </div>
</template>

<script>
// import BaseIcon from '@components/Base/BaseIcon'
import contacts from '@/api/contacts/contacts.service'

let timeout
export default {
  name: 'CommunityMembersSearch',
  // components: { BaseIcon },
  emits: ['completion'],
  data () {
    return {
      inputText: ''
    }
  },
  methods: {
    searchTimeOut () {
      clearTimeout(timeout)
      // Make a new timeout set to go off in 800ms
      timeout = setTimeout(() => {
        // if (this.inputText.length >= 3) {
        this.getCompletion()
        // }
      }, 500)
    },
    getCompletion () {
      contacts.getUsersCompletion(this.inputText).then((data) => {
        if (data.success) {
          this.$emit('completion', data.users)
        } else {
          console.error('Error while getting Cadyco users', data.error)
        }
      })
    },
    erase () {
      this.inputText = ''
      this.getCompletion()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.search-bar {
  position: relative;
  margin-bottom: 10px;

  .search-icon {
    position: absolute;
    top: 12px;
    left: 15px;
  }

  .search-input {
    padding-left: 40px;
    height: 40px;
  }
}

</style>

<template>
  <div
    class="selection-member"
  >
    <CommunityUser
      :name="member.name"
      :picture="member.picture"
      :user="member"
    />

    <Checkbox
      :model-value="isChecked"
      class="checkbox"
      :disabled="locked"
      label=""
      @update:modelValue="handleCheck"
    />
  </div>
</template>

<script>
import CommunityUser from '@components/Communities/CommunityUser'
export default {
  name: 'SelectionMember',
  components: { CommunityUser },
  props: {
    member: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  emits: ['select', 'unSelect'],
  data () {
    return {
      isChecked: this.isSelected
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.auth.user
    }
  },
  watch: {
    'isSelected' () {
      this.isChecked = this.isSelected // trigger checkbox on 'isSelected' change
    }
  },
  methods: {
    handleCheck (check) {
      if (check) {
        this.selectUser()
      } else {
        this.unSelectUser()
      }
    },
    selectUser () {
      this.$emit('select')
    },
    unSelectUser () {
      this.$emit('unSelect')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.selection-member {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #D9E2EA;
  margin-top: 15px;
  padding-bottom: 15px;

  .checkbox {
    margin-left: auto;
    margin-right: 5px;
    padding: 0;
    height: 18px;
    width: 18px;
  }
}

</style>

<template>
  <div
    class="community-panel"
    :class="{'phone': mq.phone, 'tablet': mq.tablet}"
  >
    <div class="create-community">
      <img
        src="@assets/icon_commu-black.svg"
        alt=""
      >
      <h4>{{ $t('router.views.Communities.communities') }}</h4>
      <span class="separator" />
      <img
        src="@assets/icon_add_element.svg"
        class="create-button"
        alt=""
        @click="changeMode('create')"
      >
    </div>
    <div class="separation-bar" />
    <div class="user-community-list">
      <h2>{{ $t('router.views.Communities.yourCommunities') }}</h2>
      <div class="search-bar">
        <img
          class="search-icon"
          src="@/assets/icon_search.svg"
          alt=""
          width="15"
        >
        <Input
          v-model="communitySearchInput"
          class="search-input"
          type="text"
          :placeholder="$t('router.views.Communities.searchCommunityPlaceholder')"
        />
      </div>
      <div class="scroll">
        <CommunityPreview
          v-for="(community, index) in filteredUserCommunities"
          :key="index"
          :community="community"
          :selected="selectedCommunity && (community.id === selectedCommunity.id)"
          info="createDate"
          @click="selectCommunity(community)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CommunityPreview from '@components/Communities/CommunityPreview'
export default {
  name: 'CommunityListPanel',
  components: { CommunityPreview },
  inject: ['mq'],
  emits: ['changeMode'],
  data () {
    return {
      communitySearchInput: ''
    }
  },
  computed: {
    userCommunities () {
      return this.$store.state.communities.userCommunities
    },
    selectedCommunity () {
      return this.$store.state.communities.selectedCommunity
    },
    filteredUserCommunities () {
      const communities = []
      if (this.communitySearchInput === '') {
        return this.userCommunities
      } else {
        for (let i = 0; i < this.userCommunities.length; ++i) {
          if (this.userCommunities[i].name.toLowerCase().search(this.communitySearchInput.toLowerCase()) !== -1) {
            communities.push(this.userCommunities[i])
          }
        }
        return communities
      }
    }
  },
  methods: {
    changeMode (newMode) {
      this.$emit('changeMode', newMode)
    },
    selectCommunity (community) {
      this.$router.push({ name: 'communities', params: { communityId: community.id } })
      this.communitySearchInput = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.community-panel {
  flex: 1;
  border-right: 1px solid;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;

  .create-community{
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    margin-top: 15px;

    h4 {
      text-transform: uppercase;
      font-weight: bold;
    }

    .separator {
      height: 17px;
      width: 1px;
      background-color: $color-cadyco-dark-text;
    }

    .create-button {
      cursor: pointer;
    }
  }

  .separation-bar {
    width: 90%;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 20px;
    border-top: 1px solid #DFE3E6;
  }

  .user-community-list{
    overflow-y: auto;

    h2 {
      margin-left: 5px;
    }

    .search-bar {
      position: relative;
      margin-top: 10px;
      margin-bottom: 10px;

      .search-icon {
        position: absolute;
        top: 12px;
        left: 15px;
      }

      .search-input {
        padding-left: 40px;
        height: 40px;
      }
    }

    .scroll {
      min-height: calc(100% - 110px);
    }
  }

  &.phone {
    border-right: none;

    .create-community{
      display: flex;
      justify-content:space-evenly;
      align-items: center;
      margin-top: 15px;

      img {
        width: 33px;
      }

      h4 {
        font-size: 1rem;
      }
    }

    .separation-bar {
      margin-bottom: 10px;
    }
  }

  &.tablet {
    padding: 0 20px;
    border-right: none;

    .create-community{
      display: flex;
      justify-content:flex-start;
      align-items: center;
      margin-top: 15px;

      img {
        width: 33px;
        margin-right: 14px;
      }

      h4 {
        font-size: 1rem;
        margin-right: 20px;
      }

      .separator {
        margin-right: 20px;
      }
    }

    .separation-bar {
      margin-bottom: 10px;
    }
  }
}

</style>
